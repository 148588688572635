import {
  signinRedirect,
  completeSigninRedirectFlow,
  hasValidUserSession,
} from '@onxmaps/identity-client'
import pubsub from '@/pubsub'
import { AppEvent } from '@/common/types/events.types'

const AuthLayout = () => import('@/modules/auth/AuthLayout.vue')
const AuthLogin = () => import('@/modules/auth/views/AuthLoginView.vue')
const AuthSilentRenew = () =>
  import('@/modules/auth/views/AuthSilentRenewView.vue')

const routes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: AuthLogin,
        // @ts-expect-error ignore vue-router for now
        beforeEnter: async (to) => {
          const isLoggedIn = await hasValidUserSession()
          if (!isLoggedIn) {
            console.log(to)
            signinRedirect({ after_login: to })
          }

          return false
        },
      },
      {
        path: 'silent-renew',
        name: 'silent-renew',
        component: AuthSilentRenew,
      },
      {
        path: 'callback',
        name: 'callback',
        component: AuthLogin,
        beforeEnter: async () => {
          try {
            const user = await completeSigninRedirectFlow()

            pubsub.publish(AppEvent.UserLogin, { user })

            return {
              // @ts-expect-error ignore vue-router for now
              path: user.state?.after_login.redirectedFrom.path,
              // @ts-expect-error ignore vue-router for now
              query: user.state?.after_login.redirectedFrom.query,
            }
          } catch (error) {
            console.log('login error', error)
            return { name: 'search' }
          }
        },
      },
    ],
  },
]

export default routes
