import { initIdentityClient as init } from '@onxmaps/identity-client'

export function initIdentityClient() {
  const { identity_authority, identity_client_id } = window.environment
  init({
    authority: identity_authority,
    client_id: identity_client_id,
    redirect_uri: `${window.origin}/auth/callback`,
    silent_redirect_uri: `${window.origin}/auth/silent-renew`,
    scope: 'openid email profile internal',
  })
}
