const UnitPageLayout = () => import('@/modules/unit-page/UnitPageLayout.vue')
const UnitPage = () => import('@/modules/unit-page/views/UnitPageView.vue')

const routes = [
  {
    path: '/hunt/unit-page',
    component: UnitPageLayout,
    children: [
      {
        path: ':id',
        name: 'unit-page',
        component: UnitPage,
      },
    ],
  },
]

export default routes
