import searchRoutes from '@/modules/search/search-routes'
import authRoutes from '@/modules/auth/auth-routes'
import guidedTourRoutes from '@/modules/guided-tour/guided-tour-routes'
import profileRoutes from '@/modules/profile/profile-routes'
import unitPageRoutes from '@/modules/unit-page/unit-page-routes'

const NotFound = () => import('@/views/NotFoundView.vue')

const routes = [
  ...authRoutes,
  ...searchRoutes,
  ...guidedTourRoutes,
  ...profileRoutes,
  ...unitPageRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
]

export default routes
