import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes'
import {
  checkAuth,
  hasMinimumMembershipLevel,
} from '@/middlewares/authRoutingMiddleware'
import { hasValidUserSession } from '@onxmaps/identity-client'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, _, next) => {
  const auth = await checkAuth()
  if (to.name === 'silent-renew' || to.name === 'callback') {
    next()
  } else {
    if (auth) {
      if (to.name === 'login' || to.name === 'not-found') {
        next({ name: 'search' })
      } else {
        next()
      }
    } else {
      const user = await hasValidUserSession()
      if (user && !hasMinimumMembershipLevel()) {
        window.location.href = 'https://www.onxmaps.com/hunt/upgrade'
        next()
      } else {
        if (to.name === 'login') {
          next()
        } else {
          next({ name: 'login' })
        }
      }
    }
  }
})

export default router
