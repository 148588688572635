const SearchLayout = () => import('@/modules/search/SearchLayout.vue')
const SearchTable = () => import('@/modules/search/views/SearchTableView.vue')
const SearchSplit = () => import('@/modules/search/views/SearchSplitView.vue')
const SearchMap = () => import('@/modules/search/views/SearchMapView.vue')

const routes = [
  {
    path: '/hunt',
    name: 'home',
    component: SearchLayout,
    children: [
      {
        path: '',
        name: 'search',
        component: SearchSplit,
      },
      {
        path: '/hunt/table',
        name: 'search-table',
        component: SearchTable,
      },
      {
        path: '/hunt/map',
        name: 'search-map',
        component: SearchMap,
      },
    ],
  },
]

export default routes
