import { hasValidUserSession } from '@onxmaps/identity-client'
import { useAuthStore } from '@/modules/auth/useAuthStore'

export const hasMinimumMembershipLevel = () => {
  const authStore = useAuthStore()

  return (
    authStore.isHuntEmployee ||
    authStore.isHuntElite ||
    authStore.isHuntEliteTrial
  )
}

export const checkAuth = async () => {
  const authStore = useAuthStore()
  try {
    const user = await hasValidUserSession()
    if (user) {
      await authStore.fetchSubscriptions()
    }

    if (user && hasMinimumMembershipLevel()) {
      authStore.updateIsAuthenticated(true)
      return true
    }
    authStore.updateIsAuthenticated(false)
    return false
  } catch (error) {
    authStore.updateIsAuthenticated(false)
    return false
  }
}
