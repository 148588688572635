const GuidedTourLayout = () =>
  import('@/modules/guided-tour/GuidedTourLayout.vue')
const GuidedTourLanding = () =>
  import('@/modules/guided-tour/views/GuidedTourLandingView.vue')
const GuidedTourSpecies = () =>
  import('@/modules/guided-tour/views/GuidedTourSpeciesView.vue')
const GuidedTourSexes = () =>
  import('@/modules/guided-tour/views/GuidedTourSexesView.vue')
const GuidedTourStates = () =>
  import('@/modules/guided-tour/views/GuidedTourStatesView.vue')
const GuidedTourTypes = () =>
  import('@/modules/guided-tour/views/GuidedTourTypesView.vue')
const GuidedTourWeapons = () =>
  import('@/modules/guided-tour/views/GuidedTourWeaponsView.vue')
const GuidedTourSeasons = () =>
  import('@/modules/guided-tour/views/GuidedTourSeasonsView.vue')

const routes = [
  {
    path: '/hunt/guided-tour',
    component: GuidedTourLayout,
    children: [
      {
        path: '',
        name: 'guided-tour',
        component: GuidedTourLanding,
      },
      {
        path: '/hunt/guided-tour/species',
        name: 'guided-tour-species',
        component: GuidedTourSpecies,
      },
      {
        path: '/hunt/guided-tour/sexes',
        name: 'guided-tour-sexes',
        component: GuidedTourSexes,
      },
      {
        path: '/hunt/guided-tour/states',
        name: 'guided-tour-states',
        component: GuidedTourStates,
      },
      {
        path: '/hunt/guided-tour/types',
        name: 'guided-tour-types',
        component: GuidedTourTypes,
      },
      {
        path: '/hunt/guided-tour/weapons',
        name: 'guided-tour-weapons',
        component: GuidedTourWeapons,
      },
      {
        path: '/hunt/guided-tour/seasons',
        name: 'guided-tour-seasons',
        component: GuidedTourSeasons,
      },
    ],
  },
]

export default routes
