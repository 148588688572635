import { speciesDataTransform } from '@/common/lib/speciesDataTransform'
import { weaponsDataTransform } from '@/common/lib/weaponsDataTransform'
import { DatesModel } from '@/common/models/dates.model'
import { GMUModel } from '@/common/models/gmu.model'
import { SpeciesModel } from '@/common/models/species.model'
import { StatesModel } from '@/common/models/states.model'
import { WeaponsModel } from '@/common/models/weapons.model'
import { SelectedComponent } from '@/common/types/components.types'
import isEqual from 'lodash/isEqual'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

export const useSearchFilterStore = defineStore(
  'search-filter',
  () => {
    const species = ref<SelectedComponent>({} as SelectedComponent)
    const multiSpecies = ref<SelectedComponent[]>([])
    const sexes = ref<SelectedComponent[]>([])
    const states = ref<StatesModel[]>([])
    const weapons = ref<SelectedComponent[]>([])
    const gmus = ref<GMUModel[]>([])
    const specialFilters = ref<SelectedComponent>({
      id: 1,
      label: 'Any Hunt',
      value: 'any-hunt',
    })
    const isRut = ref<boolean>(false)
    const dates = ref<DatesModel>({
      startDate: null,
      endDate: null,
    })
    const flexibleDates = ref<boolean>(false)
    const speciesDataRaw = ref<SpeciesModel[]>([])
    const statesDataRaw = ref<StatesModel[]>([])
    const weaponsDataRaw = ref<WeaponsModel[]>([])

    const speciesData = computed(() => {
      return speciesDataTransform(speciesDataRaw.value)
    })

    const statesData = computed(() => statesDataRaw.value as StatesModel[])

    const weaponsData = computed(() => {
      return weaponsDataTransform(weaponsDataRaw.value)
    })

    function updateSpecies(value: SelectedComponent) {
      species.value = value
    }

    function updateMultiSpecies(value: SelectedComponent[]) {
      multiSpecies.value = value
    }

    function updateSexes(value: SelectedComponent[]) {
      sexes.value = value
    }

    function updateStates(value: StatesModel[]) {
      states.value = value
    }

    function updateWeapons(value: SelectedComponent[]) {
      weapons.value = value
    }

    function updateGmus(value: GMUModel[]) {
      gmus.value = value
    }

    function updateDates(value: DatesModel) {
      dates.value = value
    }

    function updateSpecialFilters(value: SelectedComponent) {
      specialFilters.value = value
    }

    function updateRut(value: boolean) {
      isRut.value = value
    }

    function updateflexibleDates(value: boolean) {
      flexibleDates.value = value
    }

    function updateSpeciesData(value: SpeciesModel[]) {
      speciesDataRaw.value = value
    }

    function updateStatesData(value: StatesModel[]) {
      statesDataRaw.value = value
    }

    function updateWeaponsData(value: WeaponsModel[]) {
      weaponsDataRaw.value = value
    }

    function updateSpeciesById(speciesId: string) {
      const filteredSpecies = speciesData.value.filter(
        (item) => item.id === speciesId,
      )
      species.value = filteredSpecies[0]
    }

    function updateMultiSpeciesById(speciesIds: string) {
      const data = speciesIds.split(' ')
      const filterSpecies = speciesData.value.filter((item) =>
        data.includes(item.id.toString()),
      )
      multiSpecies.value = filterSpecies
    }

    function updateStatesById(statesId: string) {
      const data = statesId.split(' ')
      if (data.length > 1) {
        const filteredStates = statesData.value.filter((item) =>
          data.includes(item.id),
        )
        states.value = filteredStates
      } else {
        const filteredState = statesData.value.filter(
          (item) => item.id === statesId,
        )
        states.value = filteredState
      }
    }

    function updateWeaponsById(weaponsId: string) {
      const data = weaponsId.split(' ')
      if (data.length > 1) {
        const filteredWeapons = weaponsData.value.filter((item) =>
          data.includes(item.id.toString()),
        )
        weapons.value = filteredWeapons
      } else {
        const filteredWeapons = weaponsData.value.filter(
          (item) => item.id === weaponsId,
        )
        weapons.value = filteredWeapons
      }
    }

    function updateSpecialFiltersByValue(value: string) {
      if (value === 'youth-only') {
        specialFilters.value = {
          id: 2,
          label: 'Youth Only',
          value: 'youth-only',
        }
        return
      }
      if (value === 'veteran-military') {
        specialFilters.value = {
          id: 3,
          label: 'Veteran/ Military',
          value: 'veteran-military',
        }
        return
      }
    }

    function updateDatesByValue(datesParam: string) {
      const datesData = datesParam.split(' ')
      datesData[0] = datesData[0].replace('-', '/')
      const startMonth = datesData[0].split('/')[0]
      datesData[1] = datesData[1].replace('-', '/')
      const endMonth = datesData[1].split('/')[0]
      if (endMonth < startMonth) {
        let newStart = '2000/'
        let newEnd = '2001/'
        newStart = newStart.concat(datesData[0])
        newEnd = newEnd.concat(datesData[1])

        dates.value = {
          startDate: newStart,
          endDate: newEnd,
        }
      } else {
        let newStart = '2000/'
        let newEnd = '2000/'
        newStart = newStart.concat(datesData[0])
        newEnd = newEnd.concat(datesData[1])

        dates.value = {
          startDate: newStart,
          endDate: newEnd,
        }
      }
    }

    function updateSexesByValue(value: string) {
      const sexesSelected = value.split(' ')
      const sexesData = []
      if (sexesSelected.length > 1) {
        sexesData.push(
          {
            id: 1,
            label: 'Male',
            value: 'male',
          },
          {
            id: 2,
            label: 'Female',
            value: 'female',
          },
        )
        sexes.value = sexesData
        return
      }
      if (sexesSelected.length === 1 && sexesSelected.includes('male')) {
        sexesData.push({
          id: 1,
          label: 'Male',
          value: 'male',
        })
        sexes.value = sexesData
        return
      }

      sexesData.push({
        id: 2,
        label: 'Female',
        value: 'female',
      })
      sexes.value = sexesData
    }

    function updateGmusById(value: string) {
      const data = value.split(' ')
      const transformData: GMUModel[] = data.map((item) => {
        return {
          id: item,
          code: '',
          name: '',
          __typename: 'HuntPlannerGMU',
          state: {
            id: '',
            abbreviation: '',
            name: '',
            __typename: 'HuntPlannerState',
          },
        } as GMUModel
      })
      gmus.value = transformData
    }

    function resetState() {
      species.value = {} as SelectedComponent
      multiSpecies.value = []
      sexes.value = []
      states.value = []
      weapons.value = []
      gmus.value = []
      specialFilters.value.id = 1
      specialFilters.value.label = 'Any Hunt'
      specialFilters.value.value = 'any-hunt'
      dates.value = {
        startDate: null,
        endDate: null,
      }
      flexibleDates.value = false
      isRut.value = false
    }

    // watch species and state data, if it changes clear out unit filter data
    watch(species, (newValue, oldValue) => {
      if (
        Object.keys(oldValue).length !== 0 &&
        oldValue.id !== newValue.id &&
        gmus.value.length > 0
      ) {
        updateGmus([])
      }
    })

    watch(states, (newValue, oldValue) => {
      if (
        oldValue.length !== 0 &&
        !isEqual(newValue, oldValue) &&
        gmus.value.length > 0
      ) {
        updateGmus([])
      }
    })

    return {
      species,
      multiSpecies,
      sexes,
      states,
      weapons,
      gmus,
      isRut,
      specialFilters,
      dates,
      flexibleDates,
      speciesData,
      statesData,
      weaponsData,
      updateSpecies,
      updateMultiSpecies,
      updateSexes,
      updateStates,
      updateWeapons,
      updateGmus,
      updateDates,
      updateSpecialFilters,
      updateRut,
      updateflexibleDates,
      updateSpeciesData,
      updateStatesData,
      updateWeaponsData,
      updateSpeciesById,
      updateMultiSpeciesById,
      updateStatesById,
      updateWeaponsById,
      updateSpecialFiltersByValue,
      updateDatesByValue,
      updateSexesByValue,
      updateGmusById,
      resetState,
    }
  },
  {
    persist: true,
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchFilterStore, import.meta.hot))
}
