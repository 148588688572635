/* eslint-disable @typescript-eslint/ban-ts-comment */
import mParticle from '@mparticle/web-sdk'
import { type MPConfiguration, type IdentifyRequest } from 'mparticle__web-sdk'
// @ts-ignore
import amplitudeKit from '@mparticle/web-amplitude-kit'
// @ts-ignore
import appBoyKit from '@mparticle/web-braze-kit'
import { useAuthStore } from '@/modules/auth/useAuthStore'
import { isProd } from '@/env'
import pubsub from '@/pubsub'
import {
  AppEvent,
  AppForegroundedEvent,
  BackToDrawOddsSelectedEvent,
  Disabled3dEvent,
  Enabled3dEvent,
  FirstTimePromptSelectedEvent,
  FutureHuntDatesSelectedEvent,
  GuidedTourInitiateEvent,
  MainViewUpdatedEvent,
  MyPointsCardDeletedEvent,
  MyPointsCardEditedEvent,
  MyPointsNewCardAddedEvent,
  MyPointsStateAddedEvent,
  MyPointsStateRemovedEvent,
  MyPointsViewedEvent,
  MyProfileViewedEvent,
  OddsCardSelectedEvent,
  OddsListSortedEvent,
  OddsSearchExecutedEvent,
  ProfileUpdateDiscardedEvent,
  ProfileUpdateInitiatedEvent,
  ProfileUpdateSavedEvent,
  ResearchToolsOpenedEvent,
  ShareLinkRequestedEvent,
  SortTypeSelectedEvent,
  SpeciesSelectedEvent,
  SpeciesSexSelectedEvent,
  StateSelectedEvent,
  TakeMethodSelectedEvent,
  UserLoginEvent,
  WebMapOpenedEvent,
} from '@/common/types/events.types'
import { MParticleEvent, MParticleEventMap } from './events.types'

type MParticleEventData<T extends MParticleEvent> =
  T extends keyof MParticleEventMap ? MParticleEventMap[T] : unknown

const logEvent = async <T extends MParticleEvent>(
  eventName: T,
  data: MParticleEventData<T>,
  customFlags?: object,
) => {
  if (!mParticle.ready) return

  if (!isProd()) {
    console.log('SENDING MPARTICLE_EVENT: ', {
      eventName,
      data,
      customFlags,
    })
  }

  const type = mParticle.EventType.Other
  // @ts-ignore
  mParticle.logEvent(eventName, type, data, customFlags)
}

export const loginMparticle = (
  userEmail: string | null | undefined,
  account_id: string | null | undefined,
  attempts = 1,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (mParticle.Identity.getCurrentUser()?.isLoggedIn()) {
      resolve()
    } else {
      const mpIdentifyRequest: IdentifyRequest = {
        userIdentities: {},
      }

      if (account_id) {
        mpIdentifyRequest.userIdentities.customerid = account_id
      }

      if (userEmail) {
        mpIdentifyRequest.userIdentities.email = userEmail
      }

      mParticle.Identity.login(mpIdentifyRequest, (result) => {
        // if this callback returns anything other than 200, fall back to using analytics
        // router instead of the mParticle web sdk
        if (result.httpCode !== 200) {
          if (attempts <= 3) {
            setTimeout(
              () => {
                loginMparticle(userEmail, account_id, attempts + 1)
                  .then(resolve)
                  .catch(reject)
              },
              2 ** (attempts + 1) * 100,
            )
          } else {
            reject(new Error('Maximum login attempts exceeded'))
          }
        } else {
          resolve() // Login successful
        }
      })
    }
  })
}

export const onLogin = async (loginData: UserLoginEvent): Promise<void> => {
  const { email, sub } = loginData.user.profile
  await loginMparticle(email, sub)

  const authStore = useAuthStore()
  await authStore.fetchSubscriptions()

  if (mParticle.isInitialized()) {
    // Manually trigger batch upload to avoid sending events without a User ID
    mParticle.upload()
  }

  logEvent(MParticleEvent.UserLogin, {
    origin: 'hunt research tools',
    membership_level: authStore.subscriptions?.hunt.membership_level,
  })
}

export const onLogout = () => {
  mParticle.Identity.logout({})
}

const onShareLinkRequested = (data: ShareLinkRequestedEvent) =>
  logEvent(MParticleEvent.ShareLinkRequested, data)

const onFirstTimePropmptSelected = (data: FirstTimePromptSelectedEvent) =>
  logEvent(MParticleEvent.FirstTimePromptSelected, data)

const onSpeciesSelected = (data: SpeciesSelectedEvent) =>
  logEvent(MParticleEvent.SpeciesSelected, data)

const onSpeciesSexSelected = (data: SpeciesSexSelectedEvent) =>
  logEvent(MParticleEvent.SpeciesSexSelected, data)

const onStateSelected = (data: StateSelectedEvent) =>
  logEvent(MParticleEvent.StateSelected, data)

const onTakeMethodSelected = (data: TakeMethodSelectedEvent) =>
  logEvent(MParticleEvent.TakeMethodSelected, data)

const onFutureHuntDatesSelected = (data: FutureHuntDatesSelectedEvent) =>
  logEvent(MParticleEvent.FutureHuntDatesSelected, {
    ...data,
    flexible_dates: data.flexible_dates ? 'true' : 'false',
  })

const onOddsSearchExecuted = (data: OddsSearchExecutedEvent) =>
  logEvent(MParticleEvent.OddsSearchExecuted, {
    ...data,
    flexible_dates: data.flexible_dates ? 'true' : 'false',
  })

const onSortTypeSelected = (data: SortTypeSelectedEvent) =>
  logEvent(MParticleEvent.SortTypeSelected, data)

const onOddsListSorted = (data: OddsListSortedEvent) =>
  logEvent(MParticleEvent.OddsListSorted, data)

const onMainViewUpdated = (data: MainViewUpdatedEvent) =>
  logEvent(MParticleEvent.MainViewUpdated, data)

const onOddsCardSelected = (data: OddsCardSelectedEvent) =>
  logEvent(MParticleEvent.OddsCardSelected, {
    ...data,
    flexible_dates: data.flexible_dates ? 'true' : 'false',
  })

const onMyProfileViewed = (data: MyProfileViewedEvent) =>
  logEvent(MParticleEvent.MyProfileViewed, data)

const onMyPointsViewed = (data: MyPointsViewedEvent) =>
  logEvent(MParticleEvent.MyPointsViewed, data)

const onMyPointsStateAdded = (data: MyPointsStateAddedEvent) =>
  logEvent(MParticleEvent.MyPointsStateAdded, data)

const onMyPointsNewCardAdded = (data: MyPointsNewCardAddedEvent) =>
  logEvent(MParticleEvent.MyPointsNewCardAdded, data)

const onMyPointsCardEdited = (data: MyPointsCardEditedEvent) =>
  logEvent(MParticleEvent.MyPointsCardEdited, data)

const onMyPointsCardDeleted = (data: MyPointsCardDeletedEvent) =>
  logEvent(MParticleEvent.MyPointsCardDeleted, data)

const onMyPointsStateRemoved = (data: MyPointsStateRemovedEvent) =>
  logEvent(MParticleEvent.MyPointsStateRemoved, data)

const onBackToDrawOddsSelected = (data: BackToDrawOddsSelectedEvent) =>
  logEvent(MParticleEvent.BackToDrawOddsSelected, data)

const onProfileUpdateInitiated = (data: ProfileUpdateInitiatedEvent) =>
  logEvent(MParticleEvent.ProfileUpdateInitiated, data)

const onProfileUpdateDiscarded = (data: ProfileUpdateDiscardedEvent) =>
  logEvent(MParticleEvent.ProfileUpdateDiscarded, data)

const onProfileUpdateSaved = (data: ProfileUpdateSavedEvent) =>
  logEvent(MParticleEvent.ProfileUpdateSaved, data)

const onAppForegrounded = (data: AppForegroundedEvent) =>
  logEvent(MParticleEvent.AppForegrounded, {
    ...data,
    is_authenticated: data.is_authenticated ? 'true' : 'false',
    origin: 'hunt research tools',
  })

const onWebMapOpened = (data: WebMapOpenedEvent) =>
  logEvent(MParticleEvent.WebMapOpened, data)

const onEnabled3d = (data: Enabled3dEvent) =>
  logEvent(MParticleEvent.Enabled3d, data)

const onDisabled3d = (data: Disabled3dEvent) =>
  logEvent(MParticleEvent.Disabled3d, data)

const onResearchToolsOpened = async (data: ResearchToolsOpenedEvent) => {
  const authStore = useAuthStore()
  const { email, account_id } = authStore.userInfo
  if (email && account_id) {
    await loginMparticle(email, account_id)
  }
  logEvent(MParticleEvent.ResearchToolsOpened, data)
}

const onGuidedTourInitiate = (data: GuidedTourInitiateEvent) =>
  logEvent(MParticleEvent.GuidedTourInitiate, data)

const unregisterSubscribers = () => {
  pubsub.unsubscribe(AppEvent.UserLogin, onLogin)
  pubsub.unsubscribe(
    AppEvent.FirstTimePromptSelected,
    onFirstTimePropmptSelected,
  )
  pubsub.unsubscribe(AppEvent.SpeciesSelected, onSpeciesSelected)
  pubsub.unsubscribe(AppEvent.SpeciesSexSelected, onSpeciesSexSelected)
  pubsub.unsubscribe(AppEvent.StateSelected, onStateSelected)
  pubsub.unsubscribe(AppEvent.TakeMethodSelected, onTakeMethodSelected)
  pubsub.unsubscribe(
    AppEvent.FutureHuntDatesSelected,
    onFutureHuntDatesSelected,
  )
  pubsub.unsubscribe(AppEvent.OddsSearchExecuted, onOddsSearchExecuted)
  pubsub.unsubscribe(AppEvent.SortTypeSelected, onSortTypeSelected)
  pubsub.unsubscribe(AppEvent.OddsListSorted, onOddsListSorted)
  pubsub.unsubscribe(AppEvent.MainViewUpdated, onMainViewUpdated)
  pubsub.unsubscribe(AppEvent.OddsCardSelected, onOddsCardSelected)
  pubsub.unsubscribe(AppEvent.MyProfileViewed, onMyProfileViewed)
  pubsub.unsubscribe(AppEvent.MyPointsViewed, onMyPointsViewed)
  pubsub.unsubscribe(AppEvent.MyPointsStateAdded, onMyPointsStateAdded)
  pubsub.unsubscribe(AppEvent.MyPointsNewCardAdded, onMyPointsNewCardAdded)
  pubsub.unsubscribe(AppEvent.MyPointsCardEdited, onMyPointsCardEdited)
  pubsub.unsubscribe(AppEvent.MyPointsCardDeleted, onMyPointsCardDeleted)
  pubsub.unsubscribe(AppEvent.MyPointsStateRemoved, onMyPointsStateRemoved)
  pubsub.unsubscribe(AppEvent.BackToDrawOddsSelected, onBackToDrawOddsSelected)
  pubsub.unsubscribe(AppEvent.ProfileUpdateInitiated, onProfileUpdateInitiated)
  pubsub.unsubscribe(AppEvent.ProfileUpdateDiscarded, onProfileUpdateDiscarded)
  pubsub.unsubscribe(AppEvent.ProfileUpdateSaved, onProfileUpdateSaved)
  pubsub.unsubscribe(AppEvent.AppForegrounded, onAppForegrounded)
  pubsub.unsubscribe(AppEvent.WebMapOpened, onWebMapOpened)
  pubsub.unsubscribe(AppEvent.Enabled3d, onEnabled3d)
  pubsub.unsubscribe(AppEvent.Disabled3d, onDisabled3d)
  pubsub.unsubscribe(AppEvent.ResearchToolsOpened, onResearchToolsOpened)
  pubsub.unsubscribe(AppEvent.GuidedTourInitiate, onGuidedTourInitiate)
  pubsub.unsubscribe(AppEvent.ShareLinkRequested, onShareLinkRequested)
}

const registerSubscribers = () => {
  pubsub.subscribe(AppEvent.UserLogin, onLogin)
  pubsub.subscribe(AppEvent.FirstTimePromptSelected, onFirstTimePropmptSelected)
  pubsub.subscribe(AppEvent.SpeciesSelected, onSpeciesSelected)
  pubsub.subscribe(AppEvent.SpeciesSexSelected, onSpeciesSexSelected)
  pubsub.subscribe(AppEvent.StateSelected, onStateSelected)
  pubsub.subscribe(AppEvent.TakeMethodSelected, onTakeMethodSelected)
  pubsub.subscribe(AppEvent.FutureHuntDatesSelected, onFutureHuntDatesSelected)
  pubsub.subscribe(AppEvent.OddsSearchExecuted, onOddsSearchExecuted)
  pubsub.subscribe(AppEvent.SortTypeSelected, onSortTypeSelected)
  pubsub.subscribe(AppEvent.OddsListSorted, onOddsListSorted)
  pubsub.subscribe(AppEvent.MainViewUpdated, onMainViewUpdated)
  pubsub.subscribe(AppEvent.OddsCardSelected, onOddsCardSelected)
  pubsub.subscribe(AppEvent.MyProfileViewed, onMyProfileViewed)
  pubsub.subscribe(AppEvent.MyPointsViewed, onMyPointsViewed)
  pubsub.subscribe(AppEvent.MyPointsStateAdded, onMyPointsStateAdded)
  pubsub.subscribe(AppEvent.MyPointsNewCardAdded, onMyPointsNewCardAdded)
  pubsub.subscribe(AppEvent.MyPointsCardEdited, onMyPointsCardEdited)
  pubsub.subscribe(AppEvent.MyPointsCardDeleted, onMyPointsCardDeleted)
  pubsub.subscribe(AppEvent.MyPointsStateRemoved, onMyPointsStateRemoved)
  pubsub.subscribe(AppEvent.BackToDrawOddsSelected, onBackToDrawOddsSelected)
  pubsub.subscribe(AppEvent.ProfileUpdateInitiated, onProfileUpdateInitiated)
  pubsub.subscribe(AppEvent.ProfileUpdateDiscarded, onProfileUpdateDiscarded)
  pubsub.subscribe(AppEvent.ProfileUpdateSaved, onProfileUpdateSaved)
  pubsub.subscribe(AppEvent.AppForegrounded, onAppForegrounded)
  pubsub.subscribe(AppEvent.WebMapOpened, onWebMapOpened)
  pubsub.subscribe(AppEvent.Enabled3d, onEnabled3d)
  pubsub.subscribe(AppEvent.Disabled3d, onDisabled3d)
  pubsub.subscribe(AppEvent.ResearchToolsOpened, onResearchToolsOpened)
  pubsub.subscribe(AppEvent.GuidedTourInitiate, onGuidedTourInitiate)
  pubsub.subscribe(AppEvent.ShareLinkRequested, onShareLinkRequested)
}

export const initMparticle = async (): Promise<void> => {
  const { mparticle_key } = window.environment

  const authStore = useAuthStore()
  const { email, account_id } = authStore.userInfo

  const mpConfig: MPConfiguration = {
    isDevelopmentMode: !isProd(),
    identifyRequest: {
      userIdentities: {},
    },
  }

  const domainConfig = {
    v1SecureServiceUrl: 'api.mparticle.onxmaps.com/webevents/v1/JS/',
    v2SecureServiceUrl: 'api.mparticle.onxmaps.com/webevents/v2/JS/',
    v3SecureServiceUrl: 'api.mparticle.onxmaps.com/webevents/v3/JS/',
    configUrl: 'api.mparticle.onxmaps.com/tags/JS/v2/',
    identityUrl: 'api.mparticle.onxmaps.com/identity/v1/',
    aliasUrl: 'api.mparticle.onxmaps.com/webevents/v1/identity/',
  }

  if (account_id && mpConfig.identifyRequest) {
    mpConfig.identifyRequest.userIdentities.customerid = account_id
  }

  if (email && mpConfig.identifyRequest) {
    mpConfig.identifyRequest.userIdentities.email = email
  }

  const config = { ...mpConfig, ...domainConfig }
  appBoyKit.register(config)
  amplitudeKit.register(config)
  mParticle.init(mparticle_key, config)

  unregisterSubscribers()
  registerSubscribers()
}

export const unloadMparticle = () => unregisterSubscribers()
