const ProfileLayout = () => import('@/modules/profile/ProfileLayout.vue')
const ProfileAccount = () =>
  import('@/modules/profile/views/ProfileAccountView.vue')
const ProfilePoints = () =>
  import('@/modules/profile/views/ProfilePointsView.vue')

const routes = [
  {
    path: '/hunt/profile',
    component: ProfileLayout,
    children: [
      {
        path: '',
        name: 'profile-account',
        component: ProfileAccount,
      },
      {
        path: '/hunt/points',
        name: 'profile-points',
        component: ProfilePoints,
      },
    ],
  },
]

export default routes
