import { HuntType, Origin } from '@/common/types/events.types'

// MParticle does't work well with booleans, so you must send a string as a boolean :(
type MPBoolean = 'true' | 'false'

export enum MParticleEvent {
  UserLogin = 'user_login',
  FirstTimePromptSelected = 'first_time_prompt_selected',
  SpeciesSelected = 'species_selected',
  SpeciesSexSelected = 'species_sex_selected',
  StateSelected = 'state_selected',
  HuntTypeSelected = 'hunt_type_selected',
  TakeMethodSelected = 'take_method_selected',
  FutureHuntDatesSelected = 'future_hunt_dates_selected',
  OddsSearchExecuted = 'odds_search_executed',
  SortTypeSelected = 'sort_type_selected',
  OddsListSorted = 'odds_list_sorted',
  MainViewUpdated = 'main_view_updated',
  OddsCardSelected = 'odds_card_selected',
  MyProfileViewed = 'my_profile_viewed',
  MyPointsViewed = 'my_points_viewed',
  MyPointsStateAdded = 'my_points_state_added',
  MyPointsNewCardAdded = 'my_points_new_card_added',
  MyPointsCardEdited = 'my_points_card_edited',
  MyPointsCardDeleted = 'my_points_card_deleted',
  MyPointsStateRemoved = 'my_points_state_removed',
  BackToDrawOddsSelected = 'back_to_draw_odds_selected',
  ProfileUpdateInitiated = 'profile_update_initiated',
  ProfileUpdateDiscarded = 'profile_update_discarded',
  ProfileUpdateSaved = 'profile_update_saved',
  WebMapOpened = 'web_map_opened',
  AppForegrounded = 'app_foregrounded',
  Enabled3d = '3d_enabled',
  Disabled3d = '3d_disabled',
  ResearchToolsOpened = 'research_tools_opened',
  GuidedTourInitiate = 'guided_tour_initiate',
  ShareLinkRequested = 'share_link_requested',
}

type UserLoginEvent = {
  origin: 'hunt research tools'
  membership_level: string | null | undefined
}
type FirstTimePromptSelectedEvent = { origin: Origin }
type SpeciesSelectedEvent = { species: string }
type SpeciesSexSelectedEvent = { species_sex: string }
type StateSelectedEvent = { state: string }
type HuntTypeSelectedEvent = { hunt_type: HuntType }
type TakeMethodSelectedEvent = { take_method: string }
// Not sure if these should be strings or dates
type FutureHuntDatesSelectedEvent = {
  flexible_dates: MPBoolean
  start_date: string
  end_date: string
}
type OddsSearchExecutedEvent = {
  origin: Origin
  species: string
  species_sex: string
  state: string
  hunt_type: HuntType
  take_method: string
  flexible_dates: MPBoolean
  start_date: string
  end_date: string
}
type SortTypeSelectedEvent = {
  sort_type:
    | 'Public Sq Miles'
    | 'Draw Odds'
    | 'Harvest Rate'
    | 'Hunting Pressure'
}
type OddsListSortedEvent = { sort_type: 'ascending' | 'descending' }
type MainViewUpdatedEvent = { view_type: string }
type OddsCardSelectedEvent = {
  species: string
  species_detail: string
  state: string
  hunt_type: HuntType
  take_method: string
  flexible_dates: MPBoolean
  start_date: string
  end_date: string
  unit: string
  draw_odds: number
  points: number
  tags_given: number
  harvest_rate: number
  total_land: number
  public_land: number
  hunter_per_sq_km: number
}
type MyProfileViewedEvent = { origin: Origin }
type MyPointsViewedEvent = { origin: Origin }
type MyPointsStateAddedEvent = { state: string }
type MyPointsNewCardAddedEvent = {
  state: string
  species: string
  points_type: string
}
type MyPointsCardEditedEvent = {
  state: string
  species: string
  points_type: string
  edit_type: string
  total_card_points: number
}
type MyPointsCardDeletedEvent = {
  state: string
  species: string
  points_type: string
  total_card_points: number
}
type MyPointsStateRemovedEvent = { state: string }
type BackToDrawOddsSelectedEvent = undefined
type ProfileUpdateInitiatedEvent = { origin: Origin }
type ProfileUpdateDiscardedEvent = { origin: Origin }
type ProfileUpdateSavedEvent = {
  origin: Origin
  state_removed?: string
  state_added?: string
  states_applied?: string
}
type WebMapOpenedEvent = undefined
type AppForegroundedEvent = {
  is_authenticated: MPBoolean
  origin: 'hunt research tools'
}

type Enabled3dEvent = {
  active_basemap: 'hybrid'
  origin: Origin
}
type Disabled3dEvent = {
  active_basemap: 'hybrid'
  origin: Origin
}
type ResearchToolsOpenedEvent = { origin: Origin }
type GuidedTourInitiateEvent = { origin: Origin }
type ShareLinkRequestedEvent = { origin: Origin }

export type MParticleEventMap = {
  [MParticleEvent.UserLogin]: UserLoginEvent
  [MParticleEvent.FirstTimePromptSelected]: FirstTimePromptSelectedEvent
  [MParticleEvent.SpeciesSelected]: SpeciesSelectedEvent
  [MParticleEvent.SpeciesSexSelected]: SpeciesSexSelectedEvent
  [MParticleEvent.StateSelected]: StateSelectedEvent
  [MParticleEvent.HuntTypeSelected]: HuntTypeSelectedEvent
  [MParticleEvent.TakeMethodSelected]: TakeMethodSelectedEvent
  [MParticleEvent.FutureHuntDatesSelected]: FutureHuntDatesSelectedEvent
  [MParticleEvent.OddsSearchExecuted]: OddsSearchExecutedEvent
  [MParticleEvent.SortTypeSelected]: SortTypeSelectedEvent
  [MParticleEvent.OddsListSorted]: OddsListSortedEvent
  [MParticleEvent.MainViewUpdated]: MainViewUpdatedEvent
  [MParticleEvent.OddsCardSelected]: OddsCardSelectedEvent
  [MParticleEvent.MyProfileViewed]: MyProfileViewedEvent
  [MParticleEvent.MyPointsViewed]: MyPointsViewedEvent
  [MParticleEvent.MyPointsStateAdded]: MyPointsStateAddedEvent
  [MParticleEvent.MyPointsNewCardAdded]: MyPointsNewCardAddedEvent
  [MParticleEvent.MyPointsCardEdited]: MyPointsCardEditedEvent
  [MParticleEvent.MyPointsCardDeleted]: MyPointsCardDeletedEvent
  [MParticleEvent.MyPointsStateRemoved]: MyPointsStateRemovedEvent
  [MParticleEvent.BackToDrawOddsSelected]: BackToDrawOddsSelectedEvent
  [MParticleEvent.ProfileUpdateInitiated]: ProfileUpdateInitiatedEvent
  [MParticleEvent.ProfileUpdateDiscarded]: ProfileUpdateDiscardedEvent
  [MParticleEvent.ProfileUpdateSaved]: ProfileUpdateSavedEvent
  [MParticleEvent.WebMapOpened]: WebMapOpenedEvent
  [MParticleEvent.AppForegrounded]: AppForegroundedEvent
  [MParticleEvent.Enabled3d]: Enabled3dEvent
  [MParticleEvent.Disabled3d]: Disabled3dEvent
  [MParticleEvent.ResearchToolsOpened]: ResearchToolsOpenedEvent
  [MParticleEvent.GuidedTourInitiate]: GuidedTourInitiateEvent
  [MParticleEvent.ShareLinkRequested]: ShareLinkRequestedEvent
}
