<template>
  <MapProvider :options="options">
    <slot> </slot>
  </MapProvider>
</template>

<script setup lang="ts">
  import {
    RESEARCH_TOOLS_GMU_SOURCE,
    RESEARCH_TOOLS_LAYER_STYLES,
    RESEARCH_TOOLS_GMU_SOURCE_ID,
  } from '@/common/constants/gmu-styles.constants'
  import { ONX_REQUEST_HEADERS } from '@/common/constants/apiendpoints.constants'
  import { MapProvider } from '@onxmaps/map-x-vue'
  import type { MapViewerSettings } from '@onxmaps/map-x'
  import { getAccessTokenSync } from '@onxmaps/identity-client'

  const styleSheetURI =
    'https://www.onxmaps.com/api/v2/map-maker/stylesheet?app=hunt&layers=mt_public,wy_public,ca_public,nv_public,ut_public,co_public,az_public,nm_public,ak_public,id_public,or_public'
  const stylesheet = await fetch(styleSheetURI).then((r) => r.json())

  // Add gmu styles
  stylesheet.sources[RESEARCH_TOOLS_GMU_SOURCE_ID] = RESEARCH_TOOLS_GMU_SOURCE
  stylesheet.layers = [...stylesheet.layers, ...RESEARCH_TOOLS_LAYER_STYLES]

  const options: MapViewerSettings = {
    zoom: 4.2,
    center: [-98.7890613, 37.926862], //USA
    bearing: 0,
    pitch: 0,
    maxBounds: undefined,
    maxZoom: undefined,
    isAutoSelectFeatureOnClickEnabled: false,
    brand: 'hunt',
    baseMap: 'hybrid',
    layers: [
      'mt_public',
      'wy_public',
      'ca_public',
      'nv_public',
      'ut_public',
      'co_public',
      'az_public',
      'nm_public',
      'ak_public',
      'id_public',
      'or_public',
      'contour',
    ],
    style: stylesheet,
    transformRequest: (url: URL) => {
      // TODO remove this env check when production / staging tileserver can
      // accept Identity access tokens.  Currently only daily works
      if (window.environment.name !== 'daily') {
        return { url }
      }

      try {
        const requestURL = new URL(url)
        const token = getAccessTokenSync()

        // match onx domain and has token
        if (requestURL.hostname?.includes('onxmaps.com') && token) {
          // include token and headers
          const newURL = new URL(requestURL)

          newURL.hostname = requestURL.hostname.replace(
            'production',
            window.environment.name,
          )
          newURL.searchParams.append('token', token)
          // Delete api token
          newURL.searchParams.delete('api_token')
          const headers = {
            ...ONX_REQUEST_HEADERS,
            'ONX-Application-Version': import.meta.env.VITE_SHORT_SHA ?? '',
          }

          return {
            url: newURL,
            headers,
          }
        }
      } catch (e) {
        /* pass */
      }

      return { url }
    },
  }
</script>
