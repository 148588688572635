import { SpeciesModel } from '@/common/models/species.model'
import { SelectedComponent } from '@/common/types/components.types'

export function speciesDataTransform(
  data: SpeciesModel[],
): SelectedComponent[] {
  if (data) {
    const reformatResults = data.map(({ id, name }) => {
      const data = {
        id: id,
        label: name,
        value: id,
        icon: speciesIconGenerator(name),
      } as SelectedComponent
      return data
    })

    const sort = sortSpeciesAlphabetically(reformatResults)

    return sort
  }
  return []
}

const speciesIconGenerator = (speciesName: string): object => {
  // special case for deer in order to convert it to mule deer icon
  if (speciesName === 'Deer') {
    speciesName = 'Mule Deer'
  }

  const cleanName = speciesName.replace(/\s+/g, '-').toLowerCase()

  const checkIconList = speciesIcons.find((item) =>
    item.name.includes(cleanName),
  )

  if (checkIconList) {
    return {
      symbol: checkIconList.name,
      width: checkIconList.width,
      height: checkIconList.height,
    }
  } else {
    return {
      symbol: 'species',
      width: '40px',
      height: '40px',
    }
  }
}

const sortSpeciesAlphabetically = (speciesList: SelectedComponent[]) => {
  return speciesList.sort((a: SelectedComponent, b: SelectedComponent) =>
    a.label.localeCompare(b.label),
  )
}

const speciesIcons = [
  {
    name: 'antelope',
    width: '30px',
    height: '42px',
  },
  {
    name: 'bison',
    width: '42px',
    height: '30px',
  },
  {
    name: 'barbary-sheep',
    width: '42px',
    height: '30px',
  },
  {
    name: 'bighorn-sheep',
    width: '33px',
    height: '42px',
  },
  {
    name: 'ca-bighorn-sheep',
    width: '33px',
    height: '42px',
  },
  {
    name: 'desert-sheep',
    width: '33px',
    height: '42px',
  },
  {
    name: 'black-bear',
    width: '42px',
    height: '25px',
  },
  {
    name: 'brown-bear',
    width: '42px',
    height: '25px',
  },
  {
    name: 'caribou',
    width: '42px',
    height: '42px',
  },
  {
    name: 'coues-deer',
    width: '27px',
    height: '40px',
  },
  {
    name: 'dall-sheep',
    width: '50px',
    height: '25px',
  },
  {
    name: 'elk',
    width: '42px',
    height: '40px',
  },
  {
    name: 'ibex',
    width: '42px',
    height: '42px',
  },
  {
    name: 'moose',
    width: '42px',
    height: '42px',
  },
  {
    name: 'mountain-goat',
    width: '42px',
    height: '33px',
  },
  {
    name: 'mule-deer',
    width: '25px',
    height: '42px',
  },
  {
    name: 'muskox',
    width: '42px',
    height: '33px',
  },
  {
    name: 'oryx',
    width: '29px',
    height: '41px',
  },
]
