<template>
  <div v-if="!appLoading" class="research-tools-app">
    <Suspense>
      <HpMapProvider>
        <router-view></router-view>
      </HpMapProvider>
    </Suspense>
  </div>
  <div v-else class="app-loading">
    <hp-logo
      width="70px"
      height="70px"
      layout="compact"
      :is-mobile="isMobile()"
    />
    <hp-spinner width="100px" height="100px" />
    <h1>Loading...</h1>
  </div>
</template>

<script setup lang="ts">
  import HpLogo from '@/common/components/HpLogo.vue'
  import HpSpinner from '@/common/components/HpSpinner.vue'
  import HpMapProvider from '@/common/components/map/HpMapProvider.vue'
  import { isMobile } from '@/common/lib/browser'
  import { PointsModel } from '@/common/models/points.model'
  import { SpeciesModel } from '@/common/models/species.model'
  import { StatesModel } from '@/common/models/states.model'
  import { WeaponsModel } from '@/common/models/weapons.model'
  import { AppEvent } from '@/common/types/events.types'
  import { initFirebase, unloadFirebase } from '@/firebase'
  import { QUERY_APP_START } from '@/graphql/queries/queryAppStart'
  import { useAuthStore } from '@/modules/auth/useAuthStore'
  import { useProfileStore } from '@/modules/profile/useProfileStore'
  import { useSearchFilterStore } from '@/modules/search/useSearchFilterStore'
  import { initMparticle, unloadMparticle } from '@/mparticle'
  import pubsub from '@/pubsub'
  import { useSplitStore } from '@/split/useSplitStore'
  import { useHead } from '@unhead/vue'
  import { useLazyQuery } from '@vue/apollo-composable'
  import { v4 as uuidv4 } from 'uuid'
  import {
    computed,
    onMounted,
    onUnmounted,
    ref,
    watch,
    watchEffect,
  } from 'vue'

  const authStore = useAuthStore()
  const profileStore = useProfileStore()
  const splitStore = useSplitStore()
  const searchFilterStore = useSearchFilterStore()

  const appLoading = ref(true)

  const { result, load, refetch } = useLazyQuery(QUERY_APP_START)

  const query_app_start = async () => {
    load() || (await refetch())
  }

  useHead({
    titleTemplate: (title?: string) =>
      !title ? 'onX Hunt Research Tools' : `${title} - onX Hunt Research Tools`,
  })

  const pointsData = computed(
    () =>
      (result.value?.huntPlannerUserPoints.map((item: PointsModel) => {
        return { ...item, id: uuidv4() }
      }) as PointsModel[]) ?? ([] as PointsModel[]),
  )

  const onAppVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      pubsub.publish(AppEvent.AppForegrounded, {
        is_authenticated: authStore.isAuthenticated,
      })
    }
  }

  const registerListeners = () => {
    document.addEventListener('visibilitychange', onAppVisibilityChange)
  }

  const unregisterListeners = () => {
    document.removeEventListener('visibilitychange', onAppVisibilityChange)
  }

  const splitInit = async () => {
    if (authStore.userInfo.account_id) {
      await splitStore.init(authStore.userInfo.account_id)
    }
  }

  watch(result, (value) => {
    searchFilterStore.updateSpeciesData(
      (value?.huntPlannerSpecies as SpeciesModel[]) ?? ([] as SpeciesModel[]),
    )
    searchFilterStore.updateStatesData(
      (value?.huntPlannerStates as StatesModel[]) ?? ([] as StatesModel[]),
    )
    searchFilterStore.updateWeaponsData(
      (value?.huntPlannerWeapons as WeaponsModel[]) ?? ([] as WeaponsModel[]),
    )
    profileStore.updateResidency(
      (value?.huntPlannerUserResidency as StatesModel[]) ??
        ([] as StatesModel[]),
    )
    profileStore.updatePoints(pointsData.value)
  })

  watchEffect(async () => {
    if (authStore.isAuthenticated) {
      await Promise.allSettled([
        authStore.getUser(),
        authStore.fetchProfile(),
        query_app_start(),
      ])

      await splitInit()

      if (splitStore.isReady === true) {
        await splitStore.getTreatments()
      }

      pubsub.publish(AppEvent.ResearchToolsOpened, {
        origin: 'hunt research tools',
      })

      appLoading.value = false
    }
  })

  onMounted(() => {
    initMparticle()
    initFirebase()
    registerListeners()
  })

  onUnmounted(() => {
    unloadMparticle()
    unloadFirebase()
    unregisterListeners()
  })
</script>

<style scoped>
  .app-loading {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .app-loading h1 {
    margin-bottom: 2rem;
  }
</style>
