import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { hasValidUserSession, getAccessToken } from '@onxmaps/identity-client'
import {
  SUPERGRAPH_API,
  ONX_REQUEST_HEADERS,
} from '@/common/constants/apiendpoints.constants'
import router from '@/router'

const cache = new InMemoryCache()

const { api_url } = window.environment

const link = new HttpLink({
  uri: api_url + SUPERGRAPH_API,
})

const getWeekOfYear = (date = new Date()) => {
  const currDate = date ?? new Date()
  const currYear = currDate.getFullYear()
  const yearStart = new Date(currYear, 0, 1)
  const days = Math.floor(
    (currDate.getTime() - yearStart.getTime()) / (24 * 60 * 60 * 1000),
  )
  return Math.ceil(days / 7)
}

const makeBuildVersion = () => {
  // Most of this information will be blank while developing locally
  const sha = import.meta.env.VITE_SHORT_SHA
    ? `+${import.meta.env.VITE_SHORT_SHA}`
    : ''
  const release = import.meta.env.VITE_GITHUB_RUN_NUMBER
    ? `-${import.meta.env.VITE_GITHUB_RUN_NUMBER}`
    : ''

  // Use the release date if possible (on prod)
  const dateStr = import.meta.env.SENTRY_RELEASE_DATE
    ? import.meta.env.SENTRY_RELEASE_DATE
    : ''
  const releaseDate = dateStr === '' ? new Date() : new Date(dateStr)
  const year = releaseDate.getFullYear()
  const yearShort = year.toString().slice(2)
  const week = getWeekOfYear(releaseDate)
  // Example return should look like `23.2-1234+abc123`
  return `${yearShort}.${week}${release}${sha}`
}

const authLink = setContext(async (_, { headers }) => {
  let authorization

  if (await hasValidUserSession()) {
    authorization = { Authorization: `Bearer ${await getAccessToken()}` }
  } else {
    router.push('login')
  }

  return {
    headers: {
      ...headers,
      ...ONX_REQUEST_HEADERS,
      'ONX-Application-Version': '1',
      'Accept-Encoding': 'gzip',
      'Content-Type': 'application/json',
      ...authorization,
    },
  }
})

export const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
  name: `onxmaps.hunt-planner.web`,
  version: makeBuildVersion(),
  credentials: 'include',
})
