import { WeaponsModel } from '@/common/models/weapons.model'
import { SelectedComponent } from '@/common/types/components.types'

export function weaponsDataTransform(
  data: WeaponsModel[],
): SelectedComponent[] {
  if (data) {
    const reformatResults = data.map(({ id, name }) => {
      const simplifyName = name.replace(/\s+/g, '-').replace(/\./g, '')
      const data = {
        id: id,
        label: simplifyName,
        value: id,
      } as SelectedComponent
      return data
    })
    return reformatResults ?? []
  }
  return []
}
