import * as Sentry from '@sentry/vue'
import { getEnv } from '@/env'
import {
  captureConsoleIntegration,
  httpClientIntegration,
} from '@sentry/integrations'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initSentry = (app: any): void => {
  Sentry.init({
    app,
    dsn: 'https://9fa5292f46dfc175c08d59695a80e111@o240024.ingest.sentry.io/4506621697327104',
    integrations: [
      httpClientIntegration(),
      captureConsoleIntegration({ levels: ['error'] }),
    ],
    sendDefaultPii: true,
    sampleRate: getEnv() === 'prod' ? 0.01 : 1.0,
    maxBreadcrumbs: 50,
    environment: getEnv(),
    release: process.env.SENTRY_RELEASE,
    beforeBreadcrumb: function (breadcrumb) {
      // filter out noisy breadcrumb http requests
      if (
        breadcrumb.type === 'http' &&
        (breadcrumb.data?.url.includes('split.io') ||
          breadcrumb.data?.url.includes('tileserver') ||
          // Remove urls with JWT tokens
          breadcrumb.data?.url.includes('token'))
      ) {
        return null
      }
      return breadcrumb
    },
    ignoreErrors: [],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      // Cacaoweb
      /127\.0\.0\.1:4001\/isrunning/i,
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  })
}
