import { createApp, provide, h } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import 'modern-normalize'
import '@/assets/styles/reset.css'
import '@/style.css'
import router from '@/router'
import '@/firebase'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { client } from '@/graphql'
import { initIdentityClient } from '@/modules/auth/identity-client'
import { createHead } from '@unhead/vue'
import { createGtm } from '@gtm-support/vue-gtm'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { initSentry } from '@/sentry'
import { ComponentLibrary } from '@onxmaps/yellowstone-ui-vue'
import '@onxmaps/ys-tokens/dist/index.css'
import { setColorScheme, modes, themes } from '@onxmaps/ys-tokens'

setColorScheme({
  mode: modes.LIGHT,
  theme: themes.HUNT,
})

initIdentityClient()

const head = createHead()
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const gtm = createGtm({
  id: 'GTM-PH3GCV4T',
  defer: true,
  compatibility: true,
  enabled: import.meta.env.MODE === 'development' ? false : true,
  debug: import.meta.env.MODE === 'development' ? true : false,
  loadScript: true,
  vueRouter: router,
})

const app = createApp({
  setup() {
    provide(DefaultApolloClient, client)
  },
  render: () => h(App),
})

initSentry(app)

app.use(head)
app.use(pinia)
app.use(router)
app.use(gtm)
app.use(ComponentLibrary)
app.mount('#app')
