import { ref, computed } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { SubscriptionsModel } from '@/common/models/subscriptions.model'
import { UserModel } from '@/common/models/user.model'
import { ProfileModel } from '@/common/models/profile.model'
import useFetch from '@/common/composables/useFetch'
import {
  API_SUBSCRIPTIONS,
  API_PROFILE,
} from '@/common/constants/apiendpoints.constants'
import { hasValidUserSession, getUserProfile } from '@onxmaps/identity-client'
import backupProfileImg from '@/assets/empty-profile.png'
import { format } from 'date-fns'

export const useAuthStore = defineStore('auth', () => {
  const subscriptions = ref<SubscriptionsModel | null>(null)
  const user = ref<UserModel | null>(null)
  const profile = ref<ProfileModel | null>(null)
  const isAuthenticated = ref(false)

  const userInfo = computed(() => {
    return {
      account_id: profile.value?.account_id,
      fullName: `${profile.value?.first_name} ${profile.value?.last_name}`,
      firstName: profile.value?.first_name,
      lastName: profile.value?.last_name,
      email: profile.value?.email,
      picture_url: profile.value?.picture_url ?? backupProfileImg,
      membership_level: subscriptions.value?.hunt.membership_level,
    }
  })

  const isHuntElite = computed(() => {
    return subscriptions.value?.hunt.membership_level === 'elite'
  })

  const isHuntEliteTrial = computed(() => {
    return subscriptions.value?.hunt.membership_level === 'elite'
  })

  const isHuntEmployee = computed(() => {
    return subscriptions.value?.hunt.membership_level === 'employee'
  })

  const accountExpires = computed(() => {
    const date = subscriptions.value?.hunt.expires_at
      ? new Date(subscriptions.value.hunt.expires_at)
      : null
    return date ? format(date, 'M-d-Y') : null
  })

  async function fetchProfile() {
    try {
      const { data: rawData } = await useFetch(API_PROFILE)
      const json = JSON.parse(rawData.value as string) as ProfileModel
      profile.value = json
    } catch (error) {
      console.log(error)
    }
  }

  async function fetchSubscriptions() {
    try {
      if (subscriptions.value === null) {
        const { data: rawData } = await useFetch(API_SUBSCRIPTIONS)
        const json = JSON.parse(rawData.value as string) as SubscriptionsModel
        subscriptions.value = json
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getUser() {
    try {
      if (await hasValidUserSession()) {
        const data = (await getUserProfile()) as unknown
        user.value = data as UserModel
      }
    } catch (error) {
      console.log(error)
    }
  }

  function updateIsAuthenticated(value: boolean) {
    isAuthenticated.value = value
  }

  return {
    subscriptions,
    user,
    profile,
    isAuthenticated,
    userInfo,
    isHuntElite,
    isHuntEliteTrial,
    isHuntEmployee,
    accountExpires,
    fetchProfile,
    fetchSubscriptions,
    getUser,
    updateIsAuthenticated,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
