/** Utility class to help with built-in browser properties (eg. window.location) */
/**
 * Check if the browser's user agent is Android
 *
 * @return {boolean} - whether your browser is on Android or not
 */
export const isAndroid = () => {
  const agent = navigator.userAgent || navigator.vendor

  if (agent.includes('android') || agent.includes('Android')) {
    return true
  }

  return false
}

/**
 * Check if the browser's user agent is iPhone
 *
 * @return {boolean} - whether your browser is iPhone
 */
export const isiPhone = () => {
  const agent = navigator.userAgent || navigator.vendor

  if (agent.includes('iphone') || agent.includes('iPhone')) {
    return true
  }

  return false
}

/**
 * Check if the browser's user agent is iPad
 *
 * @return {boolean} - whether your browser is iPad
 */
export const isiPad = () => {
  const agent = navigator.userAgent || navigator.vendor

  if (agent.includes('ipad') || agent.includes('iPad')) {
    return true
  }

  return false
}

/**
 * Check if the browser's user agent is Firefox
 *
 * @return {boolean} - whether your browser is Firefox
 */
export const isFirefox = () => {
  return navigator.userAgent.search('Firefox') !== -1
}

/**
 * Check if the browser's user agent is Edge
 *
 * @return {boolean} - whether your browser is Edge
 */
export const isEdge = () => {
  return navigator.userAgent.indexOf('Edge') >= 0
}

/**
 * Check if the browser's user agent is IE
 *
 * @return {boolean} - whether your browser is IE
 */
export const isIE = () => {
  return window.navigator.msSaveBlob && !isEdge()
}

/**
 * Check if the browser's user agent is Chrome
 *
 * @return {boolean} - whether your browser is Chrome
 */
export const isChrome = () => {
  return (
    navigator.userAgent.indexOf('Chrome') >= 0 &&
    navigator.userAgent.indexOf('Edge') === -1
  )
}

/**
 * Check if the browser's user agent is Safari
 *
 * @return {boolean} - whether your browser is Safari
 */
export const isSafari = () => {
  return (
    navigator.userAgent.indexOf('Safari') >= 0 &&
    navigator.userAgent.indexOf('Chrome') === -1
  )
}

/**
 * Get the device info for a browser
 *
 * @return {string} info i.e. "ipad,safari"
 */
export const deviceInfo = () => {
  const deviceInfo = []

  isAndroid() && deviceInfo.push('android')
  isiPhone() && deviceInfo.push('iphone')
  isiPad() && deviceInfo.push('ipad')
  isFirefox() && deviceInfo.push('firefox')
  isIE() && deviceInfo.push('ie')
  isEdge() && deviceInfo.push('edge')
  isChrome() && deviceInfo.push('chrome')
  isSafari() && deviceInfo.push('safari')

  return deviceInfo.join(',')
}

/**
 * Check if the browser's user agent indicates a mobile device
 *
 * @return {boolean} - whether your browser is mobile
 */
export const isMobile = () => {
  return isAndroid() || isiPhone() || isiPad()
}
