export const RESEARCH_TOOLS_GMU_SOURCE_ID = 'research_tools'
export const RESEARCH_TOOLS_GMU_SOURCE = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
  promoteId: 'id',
}
export const RESEARCH_TOOLS_LAYER_STYLES = [
  {
    id: 'research_tools_gmu$gmu_fill',
    source: 'research_tools',

    type: 'fill',
    minzoom: 5,
    maxzoom: 22,
    // filter: ['has', 'style:gmu_fill'],
    layout: {
      //   visibility: 'none',
    },
    paint: {
      'fill-color': 'hsl(0, 0%, 0%)',
      'fill-opacity': 0.001,
    },
  },
  {
    id: 'research_tools_gmu$gmu_border',
    source: 'research_tools',

    type: 'line',
    minzoom: 5,
    maxzoom: 22,
    // filter: ['has', 'style:gmu_border'],
    layout: {
      //   visibility: 'none',
    },
    paint: {
      'line-color': 'hsl(120, 100%, 38%)',
      'line-width': 2,
    },
  },
  {
    id: 'research_tools_gmu$gmu_border_selected_casing',
    source: 'research_tools',

    type: 'line',
    // filter: ['has', 'style:gmu_border'],
    layout: {
      // visibility: 'none',
    },
    paint: {
      'line-color': 'hsl(0, 0%, 100%)',
      'line-width': 4.5,
      //   'line-opacity': [
      //     'case',
      //     ['boolean', ['feature-state', 'selected'], false],
      //     1,
      //     0,
      //   ],
    },
  },
  {
    id: 'research_tools_gmu$gmu_border_selected',
    source: 'research_tools',

    type: 'line',
    // filter: ['has', 'style:gmu_border'],
    layout: {
      // visibility: 'none',
    },
    paint: {
      'line-color': 'hsl(83, 100%, 42%)',
      'line-width': 2.5,
      //   'line-opacity': [
      //     'case',
      //     ['boolean', ['feature-state', 'selected'], true],
      //     1,
      //     0,
      //   ],
    },
  },
  {
    id: 'research_tools_gmu$gmu_border_label',
    source: 'research_tools',

    type: 'symbol',
    minzoom: 9,
    maxzoom: 22,
    // filter: ['has', 'style:gmu_border_label'],
    layout: {
      'text-letter-spacing': 0.25,
      'text-max-angle': ['interpolate', ['linear'], ['zoom'], 11, 15, 18, 40],
      'symbol-placement': 'line',
      'text-font': ['Open Sans ExtraBold'],
      //   visibility: 'none',
      'text-field': ['step', ['zoom'], '', 9, ['to-string', ['get', 'label']]],
      'text-offset': [0, 0.75],
      'text-size': ['interpolate', ['linear'], ['zoom'], 11, 11, 15, 16],
      'symbol-spacing': [
        'interpolate',
        ['linear'],
        ['zoom'],
        9,
        100,
        11,
        200,
        16,
        400,
      ],
    },
    paint: {
      'text-color': 'hsl(120, 100%, 38%)',
      'text-halo-width': 1.5,
      'text-halo-color': 'hsl(0, 0%, 1%)',
    },
  },
  {
    id: 'research_tools_gmu$gmu_label',
    source: 'research_tools',
    // 'source-layer': 'research_tools_gmu_label',
    type: 'symbol',
    // minzoom: 7,
    // maxzoom: 22,
    // filter: ['has', 'style:gmu_label'],
    layout: {
      'text-font': ['Open Sans ExtraBold'],
      'text-size': 17,
      'text-field': ['to-string', ['get', 'label']],
      //   visibility: 'none',
    },
    paint: {
      'text-color': 'hsl(120, 100%, 38%)',
      'text-halo-width': 1.5,
      'text-halo-color': 'hsl(0, 0%, 6%)',
    },
  },
]
