import { initializeApp } from 'firebase/app'
import { getAnalytics, setUserId } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import pubsub from '@/pubsub'
import { AppEvent, UserLoginEvent } from '@/common/types/events.types'

const firebaseConfig = {
  apiKey: 'AIzaSyBQWKJyd-U3qk9M_trVhi4wN4ZC__5XlSs',
  authDomain: 'onx-hunt-planner.firebaseapp.com',
  projectId: 'onx-hunt-planner',
  storageBucket: 'onx-hunt-planner.appspot.com',
  messagingSenderId: '229110083556',
  appId: '1:229110083556:web:a39582d39c8a7cb63ee1f1',
  measurementId: 'G-XML4QQ52TB',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const performance = getPerformance(app)

const onUserLogin = (data: UserLoginEvent) => {
  if (!analytics || !data.user?.profile?.sub) return

  setUserId(analytics, data.user.profile.sub)
}

const unregisterEventHandlers = () => {
  pubsub.unsubscribe(AppEvent.UserLogin, onUserLogin)
}

const registerEventHandlers = () => {
  pubsub.subscribe(AppEvent.UserLogin, onUserLogin)
}

export const initFirebase = () => {
  registerEventHandlers()
}

export const unloadFirebase = () => {
  unregisterEventHandlers()
}
