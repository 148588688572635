import { createFetch } from '@vueuse/core'
import { getAccessToken } from '@onxmaps/identity-client'
import { hasValidUserSession } from '@onxmaps/identity-client'
import router from '@/router'

const useFetch = createFetch({
  baseUrl: window.environment.api_url,
  options: {
    async beforeFetch({ options }) {
      const user = await hasValidUserSession()
      if (user) {
        options.headers = {
          'ONX-Application-ID': 'hunt-planner',
          'ONX-Application-Platform': 'web',
          'ONX-Application-Version': '1',
          'Accept-Encoding': 'gzip',
          'Content-Type': 'application/json',
          authorization: `Bearer ${await getAccessToken()}`,
        }
      } else {
        router.push('login')
      }
    },
  },
})

export default useFetch
