<template>
  <div class="hp-logo" :style="logoStyle">
    <hp-icon
      class="selection-icon"
      :width="props.width"
      :height="props.height"
      symbol="hunt-logo-rounded"
    />
    <div class="hp-logo-text">
      <h1 class="hp-logo-title" :style="titleStyle">Hunt Research Tools</h1>
      <div class="hp-logo-subtitle">
        Draw Odds, Tag Trends, Harvest Statistics
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import HpIcon from '@/common/components/HpIcon.vue'
  interface Props {
    layout?: 'default' | 'compact' | 'spaced' | 'mobile'
    width: string
    height: string
    isMobile?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    layout: 'default',
  })

  const titleStyle = computed(() => {
    let marginBottom = '0.25rem'

    if (props.layout === 'compact') {
      marginBottom = '0'
    } else if (props.layout === 'spaced') {
      marginBottom = '0.5rem'
    }

    return {
      marginBottom,
    }
  })

  const logoStyle = computed(() => {
    if (!props.isMobile) return {}
    return {
      maxWidth: '300px',
      marginBottom: '2rem',
    }
  })
</script>
<style scoped>
  .hp-logo {
    display: flex;
    align-items: center;
  }

  .hp-logo-text {
    margin-left: 1rem;
    color: var(--grey-100);
  }

  .hp-logo-title {
    font-size: 18px;
    font-weight: 900;
    text-decoration: none;
  }

  .hp-logo-subtitle {
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
  }

  @media (--screen-lg) {
    .hp-logo-title {
      font-size: 24px;
    }

    .hp-logo-subtitle {
      font-size: 16px;
    }
  }
</style>
