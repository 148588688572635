import DAILY_JSON from '@/env-daily.json'
import PROD_JSON from '@/env-prod.json'
import STAGING_JSON from '@/env-staging.json'

const initEnv = () => {
  setEnvVars()
}

const isLocalOrigin = () => document.location.origin.includes('localhost')

const isDailyOrigin = () =>
  document.location.origin === 'https://researchtools.daily.onxmaps.com'

const isStagingOrigin = () =>
  document.location.origin === 'https://researchtools.staging.onxmaps.com'

const isProdOrigin = () =>
  document.location.origin === 'https://researchtools.onxmaps.com'

export const getEnv = () => {
  if (isLocalOrigin()) {
    if (process.env.APP_SITE === 'daily') return 'daily'
    if (process.env.APP_SITE === 'staging') return 'staging'
    if (process.env.APP_SITE === 'prod') return 'prod'
    return 'staging'
  }
  if (isDailyOrigin()) {
    return 'daily'
  }
  if (isStagingOrigin()) {
    return 'staging'
  }
  if (isProdOrigin()) {
    return 'prod'
  }
}

export const isDaily = () => getEnv() === 'daily'

export const isStaging = () => getEnv() === 'staging'

export const isProd = () => getEnv() === 'prod'

export const setEnvVars = () => {
  if (isDaily()) {
    window.environment = DAILY_JSON
  } else if (isStaging()) {
    window.environment = STAGING_JSON
  } else if (isProd()) {
    window.environment = PROD_JSON
  } else {
    throw new Error('Failed to set environment')
  }
}

initEnv()
